import React, { useEffect, useState } from "react";
import "./FeedBackForm.css";
import Flatpickr from "react-flatpickr";
import AddressAutocomplete from "../../CustomComponents/CustomGooglePlaceAPi/AddressAutocomplete";
import { calculateToDatePlus } from "../../utils/utils";
import { showErrorToast } from "../../assets/toastUtils";

const FeedBackForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [addressData, setAddressData] = useState({});
  // console.log("addressData", addressData);

  //   const router = useRouter();
  const tours = [
    "Select a Tour",
    "Family Package",
    "Kinnour and Spiti",
    "Weekend Gateways",
    "Honeymoon Tour",
    "Offbeat Tours",
    "Volvo Package",
  ];
  const destinationsByTour = {
    "Family Package": [
      "Dalhousie",
      "Dharmshala",
      "Shimla",
      "Manali",
      "Chandigarh",
      "Kasol",
      "Amritsar",
    ],
    "Kinnour and Spiti": [
      "Sangla",
      "Kalpa",
      "Shimla",
      "Tabo",
      "Kaza",
      "Manali",
      "Chandratal",
    ],
    "Weekend Gateways": ["Kasol", "Jibhi", "Tirthan"],
    "Honeymoon Tour": ["Shimla", "Manali", "Chandigarh"],
    "Offbeat Tours": ["Kasol", "BirandPong", "Tirthan"],
    "Volvo Package": ["Dalhousie", "Dharmshala", "Shimla", "Manali"],
  };
  // console.log(destinationsByTour);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(calculateToDatePlus(new Date(), 1));
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [date, setDate] = useState(new Date());

  // console.log(startDate);

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    startDate: "",
    endDate: "",
    email: "",
    address: "",
    vehicle_no: "",
    vehicleCondition: "",
    transportation: "",
    vehicle: "",
    driver: "",
    tourTaken: "",
    ShimlaService: "",
    ManaliService: "",
    KasolService: "",
    DharmshalaService: "",
    DalhousieService: "",
    AmritsarService: "",
    ChandigarhService: "",
    SanglaService: "",
    KalpaService: "",
    TaboService: "",
    KazaService: "",
    ChandratalService: "",
    JibhiService: "",
    TirthanService: "",
    BirandPongService: "",
    suggestion: "",
  });

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleDateChange = (dates) => {
    if (!dates) {
      // If dates is null or undefined, return early
      return;
    }

    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    // Update formData with selected start and end dates in dd/mm/yyyy format
    setFormData({
      ...formData,
      startDate: start ? formatDate(start) : "", // Check if start date is valid
      endDate: end ? formatDate(end) : "", // Check if end date is valid
    });
  };
  // console.log(startDate);
  // console.log(endDate);

  const [formErrors, setFormErrors] = useState({});

  const nextStep = () => {
    if (validate()) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
    }));
  };

  // console.log(formData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    const errors = {};
    // Add validation logic for each form field
    if (currentStep === 1) {
      if (!formData.name.trim()) {
        errors.name = "Name is required";
      }
      if (!formData.email.trim()) {
        errors.email = "Email is required";
      }
      if (!formData.phone.trim()) {
        errors.phone = "Phone No. is required";
      }
      //   if (!formData.address.trim()) {
      //     errors.address = "Address is required";
      //   }
      if (!formData.vehicle_no.trim()) {
        errors.vehicle_no = "Vehicle Number is required";
      }
    } else if (currentStep === 2) {
      if (!formData.tourTaken) {
        errors.tourTaken = "Tour Taken is required";
      }
      // if (!formData.guideService) {
      //   errors.guideService = "Guide Service rating is required";
      // }
      if (!formData.transportation) {
        errors.transportation = "Transportation is required";
      }
      if (!formData.driver) {
        errors.driver = "Driver is required";
      }

      // Add validation for other step 2 fields
    } else if (currentStep === 3) {
      // if (!formData.vehicleCondition) {
      //   errors.vehicleCondition = "Vehicle Condition rating is required";
      // }
      // Add validation for other step 3 fields
    } else if (currentStep === 4) {
      // if (!formData.ShimaService) {
      //   errors.ShimaService = "Shima Service rating is required";
      // }
      // Add validation for other step 4 fields
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (validate()) {
  //     console.log(formData);
  //     // Perform additional actions like submitting the form data
  //   }
  // };

  const handleFormSubmission = async (e) => {
    e.preventDefault();
    const formattedDate = startDate.toISOString().split("T")[0];
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
 myHeaders.append("key", process.env.REACT_APP_API_KEY);
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    try {
      const response = await fetch(
        `${process.env.REACT_APP_APP_URL}/api/v1/feedback`,
        {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({
            ...formData, // Ensure `formData` is an object containing the relevant form data
            date: formattedDate, // Ensure `formattedDate`, `startDate`, and `endDate` are correctly formatted
            startDate,
            endDate,
          }),
        }
      );
      // console.log(response);
      if (response.status === 201) {
        window.open("/feedback-form/thankyou");
      } else {
        throw new Error("Failed to submit the form");
      }
      setFormData({
        name: "",
        email: "",
        phone: "",
        startDate: "",
        endDate: "",
        vehicle_no: "",
        address: "",
        guideService: "",
        transportation: "",
        driver: "",
        driverKnowledge: "",
        ShimaService: "",
        ManaliService: "",
        KasolService: "",
        DharmshalaService: "",
        DalhousieService: "",
        AmritsarService: "",
        ChandigarhService: "",
        suggestion: "",
      });
    } catch (error) {
      console.error(error);
      showErrorToast("Something Went Wrong! Please Try Again Later");
    }
  };
  // console.log(currentStep);

  const handleAddressData = (data) => {
    setAddressData(data);
  };

  return (
    <>
      <div className="container">
        <div className="form_container">
          <div className="form_content">
            <form onSubmit={handleFormSubmission}>
              {currentStep === 1 && (
                <div>
                  <div className="text_center" style={{ padding: "10px 0" }}>
                    <h3>Remarks Form</h3>
                    <p>Please help us to serve better</p>
                  </div>
                  <div className="input-flex mt-10">
                    <div>
                      <label htmlFor="name" className="text-black">
                        Name of the Guest{" "}
                      </label>
                      <input
                        className="w-full"
                        type="text"
                        name="name"
                        required
                        value={formData.name}
                        onChange={handleInputChange}
                        placeholder="eg. Sarah Smith"
                      />
                      {formErrors.name && (
                        <span className="error">{formErrors.name}</span>
                      )}
                    </div>
                    <div>
                      <label htmlFor="dob" className="text-black">
                        Email
                      </label>
                      <input
                        className="w-full"
                        type="email"
                        id="email"
                        name="email"
                        required
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder="eg. xyz123@gmail.com"
                      />
                      {formErrors.email && (
                        <span className="error">{formErrors.email}</span>
                      )}
                    </div>
                  </div>
                  <div className="input-flex mt-10">
                    <div>
                      <label htmlFor="dob" className="text-black">
                        Phone No.
                      </label>
                      <input
                        className="w-full"
                        type="number"
                        placeholder="7895425894"
                        name="phone"
                        required
                        value={formData.phone}
                        onChange={handleInputChange}
                      />
                      {formErrors.phone && (
                        <span className="error">{formErrors.phone}</span>
                      )}
                    </div>

                    <div>
                      <label htmlFor="vehicle_no" className="text-black">
                        Vehicle Number{" "}
                      </label>
                      <input
                        className="w-full"
                        type="text"
                        name="vehicle_no"
                        required
                        value={formData.vehicle_no.toUpperCase()}
                        onChange={handleInputChange}
                        placeholder="eg. HP 01D 0001"
                      />
                      {formErrors.vehicle_no && (
                        <span className="error">{formErrors.vehicle_no}</span>
                      )}
                    </div>
                  </div>
                  <div className="mt-10">
                    <label htmlFor="name">Address</label>
                    <AddressAutocomplete
                      formdata={formData}
                      onAddressChange={handleAddressData}
                    />
                    {/* {formErrors?.address && (
                      <span className="error">{formErrors.address}</span>
                    )} */}
                  </div>
                  <div>
                    {/* <div className="single_input">
                      <label htmlFor="name">Fax</label>
                      <br />
                      <input
                        type="number"
                        id="fax"
                        placeholder="(123)-456-7890"
                        name="fax"
                        value={formData.fax}
                        onChange={handleInputChange}
                      />
                      {formErrors.fax && (
                        <span className="error">{formErrors.fax}</span>
                      )}
                    </div> */}
                  </div>
                  <button
                    className="primary_btn mt-10"
                    onClick={nextStep}
                    type="button"
                  >
                    Get Started
                  </button>
                </div>
              )}

              {currentStep === 2 && (
                <div>
                  <div className="step mt-20" style={{ paddingBottom: "15px" }}>
                    {/* <h4>Name the Tour taken</h4> */}
                    <label className="text-black">Name the Tour taken </label>
                    <select
                      className="w-full p_10"
                      name="tourTaken"
                      required
                      value={formData.tourTaken}
                      onChange={handleInputChange}
                    >
                      {tours.map((tour, index) => (
                        <option key={index} value={tour}>
                          {tour}
                        </option>
                      ))}
                    </select>
                    {formErrors.tourTaken && (
                      <span className="error">{formErrors.tourTaken}</span>
                    )}
                  </div>
                  <div className="step" style={{ paddingBottom: "15px" }}>
                    <label className="text-black">Tour Date </label>
                    <div className="multistep_form_para w-full">
                      <Flatpickr
                        className="w-full m_0 p_0"
                        placeholder="select date"
                        //   value={startDate}
                        onChange={([date1, date2]) => {
                          setStartDate(date1);
                          setEndDate(date2);
                        }}
                        options={{ mode: "range" }}
                        required
                      />
                    </div>
                  </div>
                  {/* <div className="step">
                    <label >Service of Guide</label>
                    <div className="selecttag_container">
                      <input
                        type="radio"
                        className="hideCheck"
                        id="single"
                        name="guideService"
                        value="Excellent"
                        onChange={handleChange}
                      />
                      <input
                        type="radio"
                        className="hideCheck"
                        id="married"
                        name="guideService"
                        value="Good"
                        onChange={handleChange}
                      />
                      <input
                        type="radio"
                        className="hideCheck"
                        id="divorced"
                        name="guideService"
                        value="satisfactory"
                        onChange={handleChange}
                      />
                      <input
                        type="radio"
                        className="hideCheck"
                        id="separated"
                        name="guideService"
                        value="poor"
                        onChange={handleChange}
                      />

                      <label className="new single" htmlFor="single">
                        <div className="input-text">
                          <p className="multistep_form_para">Excellent</p>
                        </div>
                      </label>
                      <label className="new married" htmlFor="married">
                        <div className="input-text">
                          <p className="multistep_form_para">Good</p>
                        </div>
                      </label>
                      <label className="new divorced" htmlFor="divorced">
                        <div className="input-text">
                          <p className="multistep_form_para">Satisfactory</p>
                        </div>
                      </label>
                      <label className="new separated" htmlFor="separated">
                        <div className="input-text">
                          <p className="multistep_form_para">Poor</p>
                        </div>
                      </label>
                    </div>
                    {formErrors.guideService && (
                      <span className="error">{formErrors.guideService}</span>
                    )}
                  </div> */}

                  <div className="step">
                    <label className="text-black">Transportation</label>
                    <div className="">
                      <input
                        type="radio"
                        className="hideCheck"
                        id="excellent"
                        name="transportation"
                        value="Excellent"
                        onChange={handleChange}
                        style={{ backgroundColor: "red" }}
                      />
                      <input
                        type="radio"
                        className="hideCheck"
                        id="good"
                        name="transportation"
                        value="good"
                        onChange={handleChange}
                      />
                      <input
                        type="radio"
                        className="hideCheck"
                        id="satisfactory"
                        name="transportation"
                        value="satisfactory"
                        onChange={handleChange}
                      />
                      <input
                        type="radio"
                        className="hideCheck"
                        id="poor"
                        name="transportation"
                        value="poor"
                        onChange={handleChange}
                      />

                      <div className="input-flex">
                        <div>
                          {" "}
                          <label className="new excellent" htmlFor="excellent">
                            <div className="input-text">
                              <p
                                style={{
                                  background:
                                    formData.transportation === "Excellent"
                                      ? "#DAF5E8"
                                      : "",
                                }}
                                className="multistep_form_para w-full"
                              >
                                Excellent
                              </p>
                            </div>
                          </label>
                        </div>
                        <div>
                          <label className="new good" htmlFor="good">
                            <div className="input-text">
                              <p
                                style={{
                                  background:
                                    formData.transportation === "good"
                                      ? "#DAF5E8"
                                      : "",
                                }}
                                className="multistep_form_para w-full"
                              >
                                Good
                              </p>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="input-flex">
                        <div>
                          <label
                            className="new satisfactory"
                            htmlFor="satisfactory"
                          >
                            <div className="input-text">
                              <p
                                style={{
                                  background:
                                    formData.transportation === "satisfactory"
                                      ? "#DAF5E8"
                                      : "",
                                }}
                                className="multistep_form_para"
                              >
                                Satisfactory
                              </p>
                            </div>
                          </label>
                        </div>
                        <div>
                          <label className="new poor" htmlFor="poor">
                            <div className="input-text">
                              <p
                                style={{
                                  background:
                                    formData.transportation === "poor"
                                      ? "#DAF5E8"
                                      : "",
                                }}
                                className="multistep_form_para"
                              >
                                Poor
                              </p>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                    {formErrors.transportation && (
                      <span className="error">{formErrors.transportation}</span>
                    )}
                  </div>
                  <div className="step mt-20">
                    <label className="text-black">Driver</label>
                    <div>
                      <input
                        type="radio"
                        className="hideCheck"
                        id="driver_exc"
                        name="driver"
                        value="Excellent"
                        onChange={handleChange}
                      />
                      <input
                        type="radio"
                        className="hideCheck"
                        id="driver_good"
                        name="driver"
                        value="good"
                        onChange={handleChange}
                      />
                      <input
                        type="radio"
                        className="hideCheck"
                        id="driver_satis"
                        name="driver"
                        value="satisfactory"
                        onChange={handleChange}
                      />
                      <input
                        type="radio"
                        className="hideCheck"
                        id="driver_poor"
                        name="driver"
                        value="poor"
                        onChange={handleChange}
                      />

                      <div className="input-flex">
                        <div>
                          <label
                            className="new driver_exc"
                            htmlFor="driver_exc"
                          >
                            <div className="input-text">
                              <p
                                style={{
                                  background:
                                    formData.driver === "Excellent"
                                      ? "#DAF5E8"
                                      : "",
                                }}
                                className="multistep_form_para"
                              >
                                Excellent
                              </p>
                            </div>
                          </label>
                        </div>
                        <div>
                          <label
                            className="new driver_good"
                            htmlFor="driver_good"
                          >
                            <div className="input-text">
                              <p
                                style={{
                                  background:
                                    formData.driver === "good" ? "#DAF5E8" : "",
                                }}
                                className="multistep_form_para"
                              >
                                Good
                              </p>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="input-flex">
                        <div>
                          <label
                            className="new driver_satis"
                            htmlFor="driver_satis"
                          >
                            <div className="input-text">
                              <p
                                style={{
                                  background:
                                    formData.driver === "satisfactory"
                                      ? "#DAF5E8"
                                      : "",
                                }}
                                className="multistep_form_para"
                              >
                                Satisfactory
                              </p>
                            </div>
                          </label>
                        </div>
                        <div>
                          <label
                            className="new driver_poor"
                            htmlFor="driver_poor"
                          >
                            <div className="input-text">
                              <p
                                style={{
                                  background:
                                    formData.driver === "poor" ? "#DAF5E8" : "",
                                }}
                                className="multistep_form_para"
                              >
                                Poor
                              </p>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                    {formErrors.driver && (
                      <span className="error">{formErrors.driver}</span>
                    )}
                  </div>
                  <div className="step mt-20">
                    <label className="text-black">
                      Were the Interiors and condition of the vehicle good ?
                    </label>
                    <div>
                      <input
                        type="radio"
                        className="hideCheck"
                        id="vehicle_exc"
                        name="vehicleCondition"
                        value="Excellent"
                        onChange={handleChange}
                      />
                      <input
                        type="radio"
                        className="hideCheck"
                        id="vehicle_good"
                        name="vehicleCondition"
                        value="good"
                        onChange={handleChange}
                      />
                      <input
                        type="radio"
                        className="hideCheck"
                        id="vehicle_satis"
                        name="vehicleCondition"
                        value="satisfactory"
                        onChange={handleChange}
                      />
                      <input
                        type="radio"
                        className="hideCheck"
                        id="vehicle_poor"
                        name="vehicleCondition"
                        value="poor"
                        onChange={handleChange}
                      />

                      <div className="input-flex">
                        <div>
                          <label
                            className="new vehicle_exc"
                            htmlFor="vehicle_exc"
                          >
                            <div className="input-text">
                              <p
                                style={{
                                  background:
                                    formData.vehicleCondition === "Excellent"
                                      ? "#DAF5E8"
                                      : "",
                                }}
                                className="multistep_form_para"
                              >
                                Excellent
                              </p>
                            </div>
                          </label>
                        </div>
                        <div>
                          <label
                            className="new vehicle_good"
                            htmlFor="vehicle_good"
                          >
                            <div className="input-text">
                              <p
                                style={{
                                  background:
                                    formData.vehicleCondition === "good"
                                      ? "#DAF5E8"
                                      : "",
                                }}
                                className="multistep_form_para"
                              >
                                Good
                              </p>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="input-flex">
                        <div>
                          <label
                            className="new vehicle_satis"
                            htmlFor="vehicle_satis"
                          >
                            <div className="input-text">
                              <p
                                style={{
                                  background:
                                    formData.vehicleCondition === "satisfactory"
                                      ? "#DAF5E8"
                                      : "",
                                }}
                                className="multistep_form_para"
                              >
                                Satisfactory
                              </p>
                            </div>
                          </label>
                        </div>
                        <div>
                          <label
                            className="new vehicle_poor"
                            htmlFor="vehicle_poor"
                          >
                            <div className="input-text">
                              <p
                                style={{
                                  background:
                                    formData.vehicleCondition === "poor"
                                      ? "#DAF5E8"
                                      : "",
                                }}
                                className="multistep_form_para"
                              >
                                Poor
                              </p>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex_gap_10 mt-10">
                    <button
                      type="button"
                      onClick={prevStep}
                      className="prev_btn"
                    >
                      {/* <FaArrowLeft style={{ color: "white" }} /> */}
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 448 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ color: "white" }}
                      >
                        <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path>
                      </svg>
                    </button>
                    <button
                      type="button"
                      onClick={nextStep}
                      className="primary_btn"
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}
              {/* 
              {currentStep === 3 && (
                <div>
                  <div className="step">
                    <label>
                      Were the driver knowledgeable and knew the region well ?
                    </label>
                    <div className="selecttag_container">
                      <input
                        type="radio"
                        className="hideCheck"
                        id="driver_knwlg_exc"
                        name="driverKnowledge"
                        value="Excellent"
                        onChange={handleChange}
                      />
                      <input
                        type="radio"
                        className="hideCheck"
                        id="driver_knwlg_good"
                        name="driverKnowledge"
                        value="Good"
                        onChange={handleChange}
                      />
                      <input
                        type="radio"
                        className="hideCheck"
                        id="driver_knwlg_satis"
                        name="driverKnowledge"
                        value="Satisfactory"
                        onChange={handleChange}
                      />
                      <input
                        type="radio"
                        className="hideCheck"
                        id="driver_knwlg_poor"
                        name="driverKnowledge"
                        value="poor"
                        onChange={handleChange}
                      />

                      <label
                        className="new driver_knwlg_exc"
                        htmlFor="driver_knwlg_exc"
                      >
                        <div className="input-text">
                          <p className="multistep_form_para">Excellent</p>
                        </div>
                      </label>
                      <label
                        className="new driver_knwlg_good"
                        htmlFor="driver_knwlg_good"
                      >
                        <div className="input-text">
                          <p className="multistep_form_para">Good</p>
                        </div>
                      </label>
                      <label
                        className="new driver_knwlg_satis"
                        htmlFor="driver_knwlg_satis"
                      >
                        <div className="input-text">
                          <p className="multistep_form_para">Satisfactory</p>
                        </div>
                      </label>

                      <label
                        className="new driver_knwlg_poor"
                        htmlFor="driver_knwlg_poor"
                      >
                        <div className="input-text">
                          <p className="multistep_form_para">Poor</p>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="flex gap">
                    <button type="button" onClick={prevStep} className="prev_btn">
                      <FaArrowLeft style={{ color: "white" }} />
                    </button>
                    <button type="button" onClick={nextStep} className="next_btn">
                      Next
                    </button>
                  </div>
                </div>
              )} */}
              {currentStep === 3 && (
                <div className="pb_20">
                  <h3 className="text_center mt-20">Services at Hotels</h3>
                  {destinationsByTour[formData.tourTaken]?.map(
                    (destination, index) => (
                      <div className="step mt-10" key={index}>
                        <label>{destination}</label>
                        <div>
                          <input
                            type="radio"
                            className="hideCheck"
                            id={`${destination.toLowerCase()}_service_exc`}
                            name={`${destination}Service`}
                            value="Excellent"
                            onChange={handleChange}
                          />
                          <input
                            type="radio"
                            className="hideCheck"
                            id={`${destination.toLowerCase()}_service_good`}
                            name={`${destination}Service`}
                            value="Good"
                            onChange={handleChange}
                          />
                          <input
                            type="radio"
                            className="hideCheck"
                            id={`${destination.toLowerCase()}_service_satis`}
                            name={`${destination}Service`}
                            value="Satisfactory"
                            onChange={handleChange}
                          />
                          <input
                            type="radio"
                            className="hideCheck"
                            id={`${destination.toLowerCase()}_service_poor`}
                            name={`${destination}Service`}
                            value="Poor"
                            onChange={handleChange}
                          />

                          <div className="input-flex">
                            <div>
                              <label
                                className={`new ${destination.toLowerCase()}_service_exc`}
                                htmlFor={`${destination.toLowerCase()}_service_exc`}
                              >
                                <div className="input-text">
                                  <p
                                    style={{
                                      backgroundColor:
                                        formData[`${destination}Service`] ===
                                        "Excellent"
                                          ? "#DAF5E8"
                                          : "", // Safely access dynamic property.
                                    }}
                                    className="multistep_form_para"
                                  >
                                    Excellent
                                  </p>
                                </div>
                              </label>
                            </div>
                            <div>
                              <label
                                className={`new ${destination.toLowerCase()}_service_good`}
                                htmlFor={`${destination.toLowerCase()}_service_good`}
                              >
                                <div className="input-text">
                                  <p
                                    style={{
                                      backgroundColor:
                                        formData[`${destination}Service`] ===
                                        "Good"
                                          ? "#DAF5E8"
                                          : "", // Safely access dynamic property.
                                    }}
                                    className="multistep_form_para"
                                  >
                                    Good
                                  </p>
                                </div>
                              </label>
                            </div>
                          </div>
                          <div className="input-flex">
                            <div>
                              <label
                                className={`new ${destination.toLowerCase()}_service_satis`}
                                htmlFor={`${destination.toLowerCase()}_service_satis`}
                              >
                                <div className="input-text">
                                  <p
                                    style={{
                                      backgroundColor:
                                        formData[`${destination}Service`] ===
                                        "Satisfactory"
                                          ? "#DAF5E8"
                                          : "", // Safely access dynamic property.
                                    }}
                                    className="multistep_form_para"
                                  >
                                    Satisfactory
                                  </p>
                                </div>
                              </label>
                            </div>
                            <div>
                              <label
                                className={`new ${destination.toLowerCase()}_service_poor`}
                                htmlFor={`${destination.toLowerCase()}_service_poor`}
                              >
                                <div className="input-text">
                                  <p
                                    style={{
                                      backgroundColor:
                                        formData[`${destination}Service`] ===
                                        "Poor"
                                          ? "#DAF5E8"
                                          : "", // Safely access dynamic property.
                                    }}
                                    className="multistep_form_para"
                                  >
                                    Poor
                                  </p>
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                  <div className="step mt-10">
                    <label>Suggestion</label>
                    <div className="selecttag_container">
                      <textarea
                        onChange={handleInputChange}
                        id="suggestion"
                        name="suggestion"
                        rows="4"
                        cols=""
                        className="w-full mt-5"
                      ></textarea>
                    </div>
                  </div>
                  <div className="flex flex_gap_10 mt-10">
                    <button
                      type="button"
                      onClick={prevStep}
                      className="prev_btn"
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 448 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ color: "white" }}
                      >
                        <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path>
                      </svg>
                    </button>
                    <button
                      type="submit"
                      // onClick={handleSubmit}
                      className="primary_btn"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default FeedBackForm;
