import React, { useEffect } from "react";
import Flatpickr from "react-flatpickr";
import { useState } from "react";
import { textDateFormatter } from "../../utils/utils";
import VendorHeader from "../../CustomComponents/Property/VendorHeader/VendorHeader";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import SideBar from "../../CustomComponents/Property/SideBar/SideBar";

const RatesBulkUpdate = ({ onClose }) => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  // console.log(fromDate);

  const { id } = useParams();

  const navigate = useNavigate();

  const [resultArray, setResultArray] = useState([]);
  // console.log(resultArray);

  const [ratesData, setRatesData] = useState([]);
  // const [updatePopup, setUpdatePopup] = useState(false);

  // console.log(ratesData);

  // useEffect(() => {
  //   // Function to process the room data
  //   const processRoomRates = (data) => {
  //     const processedData = data.map(room => {
  //       const roomRates = Object.entries(room.rate).flatMap(([planType, rates]) => {
  //         return rates.map(rate => ({
  //           ...rate,
  //           planType,
  //           roomName: room.name
  //         }));
  //       });
  //       return { roomName: room.name, roomRates };
  //     });
  //     return processedData;
  //   };

  //   const processedData = processRoomRates(ratesData);
  //   console.log(processedData);
  // }, [ratesData]);

  const ratesDetails = (propertyId) => {
    const myHeaders = new Headers();
    myHeaders.append("key", process.env.REACT_APP_API_KEY);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/vendor/hotel-rates/${propertyId}/bulk-update`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);

        if (result.success) {
          setRatesData(result?.rooms);
        } else {
        }
        // console.log(result);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (id) {
      ratesDetails(id);
    }
  }, [id]);

  const handleClosePopup = (e) => {
    onClose();
  };

  useEffect(() => {
    if (ratesData) {
      const inventoryNewData = ratesData.map((data) => {
        // console.log(data);

        return {
          room_name: data.id,
          inventory: "",
          rateplans: data.rateplans.map((rate) => {
            return {
              plan_name: rate.id,
              name: rate.name,
              contracted_rate: "",
              base_rate: "",
              extra_adult_rate: "",
              child_w_bed_rate: "",
              child_wo_bed_rate: "",
            };
          }),
        };
      });
      setResultArray(inventoryNewData);
    }
  }, [ratesData]);

  // console.log(resultArray);

  const onChangeHandler = (e, index) => {
    const { name, value } = e.target;
    const newArray = [...resultArray];
    newArray[index][name] = value;
    setResultArray(newArray);
  };

  const raw = JSON.stringify({
    "from-date": fromDate,
    "to-date": toDate,
    rooms: resultArray,
  });

  const handleOnUpdate = () => {
    // const myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("key", "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1");
    // myHeaders.append("token", localStorage.getItem("token"));

    const myHeaders = {
      "Content-Type": "application/json",
      key: "D4X50uU40Vz4pweFLEmsoiJy107iPfTD4ompprop1",
      token: `Bearer ${localStorage.getItem("token")}`,
    };

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APP_URL}/api/v1/vendor/hotel-rates/${id}/bulk-update`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          toast.success(result.message);
          navigate(`/partner/rates/${id}`);
        }
      })
      .catch((error) => console.error(error));
  };

  const handleInputChange = (e, roomIndex, rateIndex, fieldName) => {
    const { value } = e.target;
    const updatedRatesData = [...resultArray];
    updatedRatesData[roomIndex].rateplans[rateIndex][fieldName] = value;
    setResultArray(updatedRatesData);
  };

  return (
    <>
      <VendorHeader />
        <div className="sidebar_main_div">
        <SideBar allPropertiesPage={false} />
          <div className="inventory_update w-full">
            <div className="flex justify_content_between">
              <h4>Rates Bulk Update</h4>
              <div className="close_popup"></div>
            </div>
            <div>
              <form action="" className="mt-10">
                <div className="input-flex">
                  <div>
                    <label>From Date</label>
                    <input
                      className="w-full"
                      type="date"
                      value={fromDate}
                      onChange={(e) => setFromDate(e.target.value)}
                    />
                  </div>
                  <div>
                    <label>ToDate</label>
                    <input
                      className="w-full"
                      type="date"
                      value={toDate}
                      onChange={(e) => setToDate(e.target.value)}
                      min={fromDate}
                    />
                  </div>
                </div>
              </form>
              {ratesData?.map((item, roomIndex) => {
                return (
                  <div className="py-5">
                    <div className="">
                      <span className="py-10 block">
                        {" "}
                        <b>{ratesData[roomIndex].name}</b>{" "}
                      </span>
                      {/* <input
                        type="number"
                        name="inventory"
                        style={{ width: "50px" }}
                        value={item?.inventory}
                        onChange={(e) => onChangeHandler(e, index)}
                      /> */}
                      <div className="">
                        {item?.rateplans?.map((rate, rateIndex) => {
                          return (
                            <div key={rateIndex}>
                              <div className="pt_10">
                                <small className="font_12">{rate?.name}</small>
                              </div>
                              <div className="mt-10 justify_content_between w-100 form-flex-div">
                                {/* <div>
                            <div>Contracted Rate</div>
                            <div>
                              <input
                                type="text"
                                value={rate.contracted_rate}
                                onChange={(e) => handleInputChange(e, roomIndex, rateIndex, 'contracted_rate')}
                              />
                            </div>
                          </div> */}
                                <div>
                                  <div>Base Rate</div>
                                  <div>
                                    <input
                                      className="w-100"
                                      type="text"
                                      value={rate.base_rate}
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          roomIndex,
                                          rateIndex,
                                          "base_rate"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div>
                                  <div>Extra Adult Rate</div>
                                  <div>
                                    <input
                                      type="text"
                                      className="w-100"
                                      value={rate.extra_adult_rate}
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          roomIndex,
                                          rateIndex,
                                          "extra_adult_rate"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div>
                                  <div>Child with Bed Rate</div>
                                  <div>
                                    <input
                                      className="w-100"
                                      type="text"
                                      value={rate.child_w_bed_rate}
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          roomIndex,
                                          rateIndex,
                                          "child_w_bed_rate"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div>
                                  <div>Child w/o Bed Rate</div>
                                  <div>
                                    <input
                                      className="w-100"
                                      type="text"
                                      value={rate.child_wo_bed_rate}
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          roomIndex,
                                          rateIndex,
                                          "child_wo_bed_rate"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
              <button
                className="primary_btn fit-width mt-10"
                type="submit"
                onClick={handleOnUpdate}
              >
                Update
              </button>
            </div>
          </div>
        </div>{" "}
    </>
  );
};

export default RatesBulkUpdate;
